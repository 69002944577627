//font variables 👇🏽
$main-font: "Inter", sans-serif;
$special-font: "Syne", sans-serif;

//color variables 👇🏽
$black-clr: #0a010c;
$white-clr: #fafafa;
$green-clr: #1a2c37;

//layout variables 👇🏽
$desktop-padding: 64px;
$mobile-padding: 14px;
$desktop-width: calc(100vw - ($desktop-padding * 2));
$mobile-width: calc(100vw - ($mobile-padding * 2));

@mixin mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}

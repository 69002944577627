@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Syne:wght@400..800&display=swap");
@import "../sass/vars";

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: $main-font;
  background-color: $white-clr;
  overflow-x: hidden;
}

.header {
  &__container {
    width: 100vw;
    height: 1024px;
    background-image: url("../../public/assets/header.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: 210px;

    @include mobile {
      height: 750px;
    }
  }
}

.navbar {
  &__container {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__wrapper {
    width: $desktop-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 44px 0;

    @include mobile {
      width: $mobile-width;
    }
  }

  &__menu {
    all: unset;
    cursor: pointer;

    @include mobile {
      & > svg {
        width: 42px;
        height: 42px;
      }
    }
  }
}

.content {
  &__container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__box {
    width: 795px;
    padding-left: $desktop-padding;
    display: flex;
    flex-direction: column;

    @include mobile {
      width: $mobile-width !important;
      padding-left: $mobile-padding;
    }

    & > h1 {
      font-size: 96px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      color: $white-clr;

      @include mobile {
        font-size: 38px;
      }
    }

    & > p {
      font-size: 24px;
      font-weight: 400;
      color: $white-clr;
      margin-top: 24px;

      @include mobile {
        font-size: 16px;
      }
    }

    & > button {
      all: unset;
      width: 587px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 64px;
      background-color: $white-clr;
      gap: 85px;
      margin-top: 82px;

      @include mobile {
        width: max-content;
        gap: 10px;
        height: 60px;
        margin-top: 32px;
        padding: 0 25px 0px 5px;
      }

      & > div {
        height: 90px;
        width: 90px;
        background-color: $green-clr;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;

        @include mobile {
          height: 50px;
          width: 50px;
          margin-left: 0;

          & > svg {
            width: 32px;
            height: 32px;
          }
        }
      }

      & > span {
        font-size: 40px;
        font-weight: 500;
        font-style: normal;
        color: $green-clr;

        @include mobile {
          font-size: 20px;
        }
      }
    }
  }
}

.service {
  &__container {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black-clr;
  }

  &__wrapper {
    width: $desktop-width;
    height: auto;
    padding: 160px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__titles {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 67px;

    & > h2 {
      font-size: 64px;
      font-weight: 600;
      font-style: normal;
      color: $white-clr;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    &--links {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      & > h3 {
        font-size: 40px;
        font-weight: 600;
        font-style: normal;
        color: rgba($color: $white-clr, $alpha: 0.3);
        cursor: pointer;
        transition: 250ms color ease-out;

        &:hover {
          color: $green-clr;
        }

        &:nth-child(1) {
          color: $white-clr;
        }
      }
    }
  }

  &__slides {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 38px;

    & > img {
      object-fit: cover;
      object-position: center center;
      border-radius: 20px;
    }

    & > p {
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      color: $white-clr;
      line-height: 32px;
    }
  }
}

.menu {
  &__container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: url("../../public/assets/menu.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translateX(100%);
    transition: transform 350ms ease-in-out;

    &.open {
      transform: translateX(0);
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__box {
    width: $desktop-width;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    padding: 80px 0;

    @include mobile {
      width: $mobile-width;
      gap: 100px;
    }

    & > button {
      all: unset;
      width: auto;
      height: auto;
      align-self: flex-end;
      cursor: pointer;

      & > svg {
        width: 62px;
        height: 62px;
        color: $white-clr;

        @include mobile {
          width: 32px !important;
          height: 32px !important;
        }
      }
    }
  }

  &__links {
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;

    @include mobile {
      width: 100%;
      height: 100%;
      justify-content: flex-end;
      gap: 20px;
    }

    & > a {
      text-decoration: none;
      font-size: 72px;
      font-weight: 900;
      color: $white-clr;
      transition: 250ms color ease-out;
      letter-spacing: 2px;

      @include mobile {
        font-size: 36px;
      }

      &:nth-child(2n + 2) {
        padding-left: 60px;

        @include mobile {
          padding-left: 0;
        }
      }

      &:hover {
        color: $green-clr;
      }
    }
  }
}

.about {
  &__container {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: $white-clr;
  }

  &__wrapper {
    width: $desktop-width;
    display: flex;
    flex-direction: column;
    gap: 72px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 120px 0;

    @include mobile {
      width: $mobile-width;
      padding: 60px 0;
      gap: 32px;
    }

    & > h2 {
      font-size: 64px;
      font-weight: 600;
      color: $green-clr;
      font-style: normal;

      @include mobile {
        font-size: 28px;
        font-weight: 700;
      }
    }
  }

  &__box {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__cards {
    min-height: 720px;
    min-width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 20px;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    &--card {
      grid-column: span 2 / span 2;
      grid-row: span 4 / span 4;
      overflow: hidden;
      border-radius: 20px;
      background-image: url("../../public/assets/header.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;

      @include mobile {
        width: 100%;
        height: 420px;
      }

      &__box {
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        transition: all 300ms ease-in;

        &:hover {
          .content {
            transform: translateY(0);

            & > h3 {
              &::after {
                transform: scaleX(1);
              }
            }
          }
        }

        & > .content {
          width: 100%;
          padding: 40px 20px;
          display: flex;
          flex-direction: column;
          gap: 40px;
          transform: translateY(50%);
          transition: transform 300ms ease-in;

          @include mobile {
            transform: translateY(0);
            gap: 20px;
          }

          & > h3 {
            font-size: 38px;
            font-weight: 600;
            font-style: normal;
            color: $white-clr;
            position: relative;
            width: max-content;

            @include mobile {
              font-size: 28px;
            }

            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 4px;
              border-radius: 20px;
              left: 0;
              bottom: -10px;
              background-color: $white-clr;
              transform: scaleX(0);
              transform-origin: left;
              transition: transform 400ms linear;

              @include mobile {
                transform: scaleX(1);
              }
            }
          }

          & > p {
            font-size: 16px;
            font-weight: 400;
            color: rgba($color: $white-clr, $alpha: 0.8);
            line-height: 24px;

            @include mobile {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }

      &:nth-child(2) {
        grid-column: span 2 / span 2;
        grid-row: span 2 / span 2;
        grid-column-start: 3;
      }

      &:nth-child(3) {
        grid-column: span 2 / span 2;
        grid-row: span 2 / span 2;
        grid-column-start: 3;
        grid-row-start: 3;
      }
    }
  }

  &__team {
    width: 100%;
    min-height: 561px;
    height: 561px;
    background-image: url("../../public/assets/header.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    border-radius: 20px;

    @include mobile {
      max-height: 120px;
    }

    &--box {
      width: 100%;
      min-height: 100%;
      display: flex;
      align-items: end;
      justify-content: flex-end;

      @include mobile {
        align-items: flex-end;
        justify-content: flex-start;
      }

      &__content {
        display: flex;
        flex-direction: column;
        width: 680px;
        height: max-content;
        gap: 20px;
        margin: 0 54px 66px 0;

        @include mobile {
          margin: 0;
          width: 100%;
          min-height: auto;
          padding: 0 20px 20px 20px;
        }

        & > h3 {
          font-size: 64px;
          font-weight: 800;
          color: $white-clr;
          letter-spacing: 2px;

          @include mobile {
            font-size: 32px;
            font-weight: 700;
          }
        }

        & > p {
          font-size: 18px;
          font-weight: 400;
          color: rgba($color: $white-clr, $alpha: 0.8);
          line-height: 24px;

          @include mobile {
            font-size: 17px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.footer {
  &__container {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $green-clr;
  }

  &__wrapper {
    width: $desktop-width;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 120px 0;

    @include mobile {
      width: $mobile-width;
      padding: 60px 0;
      flex-direction: column-reverse;
      gap: 60px;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &--mycontact {
      display: none !important;

      @include mobile {
        display: block !important;
      }
    }

    & > p {
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      color: rgba($color: $white-clr, $alpha: 0.7);
      margin-bottom: 60px;
      width: 600px;
      line-height: 22px;

      @include mobile {
        width: 100%;
        font-size: 15px;
      }
    }

    &--content {
      display: flex;
      align-items: flex-start;
      gap: 80px;
      margin-bottom: 60px;

      @include mobile {
        width: 100%;
        margin-bottom: 40px;
        justify-content: space-between;
        gap: 30px;
        flex-wrap: wrap;
      }

      &__links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        & > span {
          font-size: 22px;
          font-weight: 700;
          color: $white-clr;
          margin-bottom: 10px;
          letter-spacing: 2px;

          // @include mobile {
          //   font-size: 24px;
          // }
        }

        & > a {
          all: unset;
          cursor: pointer;
          color: rgba($color: $white-clr, $alpha: 0.7);
          margin-top: 10px;
          font-size: 16px;
          font-weight: 600;

          // @include mobile {
          //   font-size: 18px;
          //   font-weight: 700;
          // }
        }
      }
    }

    &--contact {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;

      @include mobile {
        gap: 15px;
      }

      &__address {
        width: 100%;
        height: auto;

        & > span {
          font-size: 28px;
          font-weight: 600;
          color: rgba($color: $white-clr, $alpha: 0.7);

          @include mobile {
            font-size: 24px;
            font-weight: 700;
          }
        }
      }

      &__sociaux {
        display: flex;
        width: 100%;
        height: auto;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;

        & > a {
          all: unset;
          cursor: pointer;

          & > svg {
            width: 32px;
            height: 32px;
            color: rgba($color: $white-clr, $alpha: 0.7);

            @include mobile {
              width: 28px;
              height: 28px;
            }
          }
        }
      }
    }
  }

  &__logo {
    font-size: 102px;
    font-weight: 800;
    color: $white-clr;
    font-style: normal;
    letter-spacing: 2px;

    @include mobile {
      font-size: 72px;
    }
  }

  &__right {
    @include mobile {
      display: none;
    }
  }

  &__contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 500px;
    margin-top: 104px;

    @include mobile {
      width: 100%;
      margin-top: 0;
      gap: 20px;
      height: 280px;
    }

    & > h2 {
      font-size: 51px;
      font-weight: 800;
      color: $white-clr;
      font-style: normal;
      letter-spacing: 2px;

      @include mobile {
        font-size: 28px;
      }
    }

    & > p {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      font-style: normal;
      color: rgba($color: $white-clr, $alpha: 0.7);

      @include mobile {
        font-size: 15px;
        font-weight: 700;
      }
    }

    &--slider {
      position: relative;
      width: 100%;
      height: 80px;
    }

    &--first {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 20px;
      opacity: 0;
      transition: all 300ms ease-in-out;

      &.one,
      &.two,
      &.three,
      &.four {
        opacity: 1;
        z-index: 2;
      }

      & > h3 {
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        color: rgba($color: $white-clr, $alpha: 0.7);

        @include mobile {
          font-size: 17px;
          font-weight: 700;
        }
      }

      &__gender {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        flex-wrap: wrap;

        & > .input {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          gap: 20px;

          & > input {
            all: unset;
            width: 100%;
            border-bottom: 1px solid rgba($color: $white-clr, $alpha: 0.8);
            padding: 10px 0;
            font-size: 16px;
            color: rgba($color: $white-clr, $alpha: 0.8);
            transition: all 300ms ease-out;

            &.unchecked {
              border-bottom: 1px solid rgba($color: #a44444, $alpha: 1);
            }

            &.checked {
              border-bottom: 1px solid rgba($color: $white-clr, $alpha: 1);
            }
          }

          & > button {
            all: unset;
            padding: 10px 20px;
            background-color: rgba($color: $white-clr, $alpha: 0.8);
            cursor: pointer;

            & > svg {
              width: 17px;
              height: 17px;
              color: $black-clr;
            }
          }
        }

        & > button {
          all: unset;
          width: auto;
          height: auto;
          padding: 10px 24px;
          background-color: rgba($color: $white-clr, $alpha: 0.8);
          border-radius: 20px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          color: $black-clr;

          @include mobile {
            font-size: 12px;
            font-weight: 800;
          }

          & > svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  &__copyright {
    margin-top: 40px;
    border-top: 1px solid rgba($color: $white-clr, $alpha: 0.8);
    padding-top: 20px;
    width: 100%;
    text-align: center;

    & > span {
      text-align: center;
      font-size: 18px;
      color: rgba($color: $white-clr, $alpha: 0.8);

      @include mobile {
        font-size: 16px;
      }
    }
  }
}
